/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Flex, Link, Text } from '@bottlebooks/gatsby-theme-base';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import { jsx } from 'theme-ui';

export default function Producer({
  producer,
  hideDenomination = false,
  ...rest
}) {
  const link = useLink();
  return (
    <Flex direction="column" {...rest}>
      {!hideDenomination && (
        <Text
          variant="groupLabel"
          sx={{
            borderLeft: producer.denomination ? 3 : 0,
            borderColor: 'primary',
            paddingLeft: 1,
            height: 36,
            marginBottom: 1,
          }}
        >
          {producer.denomination}
        </Text>
      )}
      <Link
        to={link.producer(producer)}
        fallback="div"
        sx={{
          flex: '1 0 auto',
          transition: 'none',
          position: 'relative',
        }}
      >
        {producer.mainImage?.fluid && (
          <Image
            fluid={producer.mainImage.fluid}
            sx={{ maxWidth: '100%' }}
            alt={producer.name}
          />
        )}
        <Text
          variant="gridLabel"
          sx={{
            textAlign: 'center',
            padding: 2,
            position: 'absolute',
            inset: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textShadow: producer.mainImage?.fluid
              ? '0px 0px 2px black, 0px 0px 4px black, 0px 0px 8px black, 0px 0px 16px black'
              : 'none',
            ...cssOverlay(producer.mainImage?.fluid ? 'none' : 'overlayLight'),
            color: producer.mainImage?.fluid ? 'onDark' : 'text',
            ':hover': {
              backgroundColor: 'overlayPrimary',
              color: 'onPrimary',
              textShadow: 'none',
            },
          }}
        >
          {producer.name}
        </Text>
      </Link>
    </Flex>
  );
}

export const fragment = graphql`
  fragment Producer on Bottlebooks_Producer {
    name
    slug(format: NAME_AND_HASH)
    mainImage {
      fluid(maxWidth: 280, maxHeight: 280, crop: FILL, gravity: FACES_AUTO) {
        src
        sizes
        aspectRatio
      }
    }
    ...useLink_bb_Producer
  }
`;

Producer.fragment = gql`
  fragment Producer on Producer {
    name
    slug(format: NAME_AND_HASH)
    mainImage {
      fluid(maxWidth: 280, maxHeight: 280, crop: FILL, gravity: FACES_AUTO) {
        src
        sizes
        aspectRatio
      }
    }
  }
`;
