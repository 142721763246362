export default function groupProducers(allProducers) {
  // This is a quick and dirty way to ensure that DOs appear before
  // VC, VP and VDLT. Vino de mesa comes last
  // It takes advantage of the fact that this can be done with
  // a simple alpha sort.
  const groupedAndSortedByDO = allProducers
    .sort((a, b) => {
      return a.sortByDenomination > b.sortByDenomination ? 1 : -1;
    })
    .reduce((acc, producer) => {
      const { sortByDenomination } = producer;
      if (!acc[sortByDenomination]) acc[sortByDenomination] = [];
      acc[sortByDenomination].push(producer);
      acc[sortByDenomination].sort((a, b) => (a.name > b.name ? 1 : -1));
      return acc;
    }, {});

  const producers = Object.values(groupedAndSortedByDO).reduce((acc, group) => {
    acc = acc.concat(group);
    return acc;
  }, []);

  // We need to ensure that denomination is only rendered 1 time
  const { producers: enumeratedProducers } = producers.reduce(
    (acc, producer) => {
      const { denomination } = producer;
      const filtered = {
        ...producer,
        denomination: acc.currentDenomination !== denomination && denomination,
      };
      acc.producers.push(filtered);
      acc.currentDenomination = denomination;
      return acc;
    },
    { currentDenomination: '', producers: [] }
  );
  return enumeratedProducers;
}
