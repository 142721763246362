/** @jsxRuntime classic */
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Grid } from '@bottlebooks/gatsby-theme-base';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Producer from '../Producer';

export default function ExploreWinesByProducer({ producers, ...rest }) {
  return (
    <Grid width={140} height={180} gap={3} sx={{ rowGap: 4 }} {...rest}>
      {producers.map((producer) => (
        <Producer
          key={`${producer.registrationId}:${producer.brandId}`}
          producer={producer}
        />
      ))}
    </Grid>
  );
}

export const fragment = graphql`
  fragment ExploreWinesByProducer on Bottlebooks_Producer {
    brandId
    ...Producer
  }
`;
ExploreWinesByProducer.fragment = gql`
  fragment ExploreWinesByProducer on Producer {
    brandId
    ...Producer
  }
  ${Producer.fragment}
`;
