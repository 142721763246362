/** @jsxRuntime classic */
/** @jsx jsx */
import { Container, Link, Text } from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import SiteSearchSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/SiteSearchSection';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ExploreWinesByProducer from '../../components/ExploreWines/ExploreWinesByProducer';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';
import formatProducers from '../../formatProducers';
import groupProducers from '../../groupProducers';

export default function SeekingRepresentationPage({ pageContext, data }) {
  const { event, producers } = afterQuery(data);
  return (
    <LinkProvider value={pageContext.paths}>
      <Layout>
        <EventBanner event={event} withOverlay={false} />
        <SiteSearchSection locale={pageContext.locale} />
        <Container>
          <ExploreWinesSwitcher>
            <Link to="/products">
              <Text>
                Or search by vegan, organic, on-trade and 10 other filters….
              </Text>
            </Link>
          </ExploreWinesSwitcher>
          <ExploreWinesByProducer producers={producers} sx={{ marginY: 3 }} />
        </Container>
      </Layout>
    </LinkProvider>
  );
}

function afterQuery(data) {
  const { bottlebooks } = data;
  const event = bottlebooks.event;
  // const producers = event.producers?.nodes;
  const producers = formatProducers(event);
  const enumeratedProducers = groupProducers(producers);
  return { event, producers: enumeratedProducers };
}

export const query = graphql`
  query SeekingRepresentationPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        name
        ...bb_EventBanner
        products: registeredProducts {
          byRegion: group(
            field: denomination
            sort: { fields: totalCount, order: DESC }
          ) {
            fieldValue
            totalCount
            byProducer: group(
              field: producerName
              first: 1
              sort: { fields: fieldValue }
            ) {
              fieldValue
              totalCount
              nodes {
                product {
                  producer {
                    producerId
                    ...ExploreWinesByProducer
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
